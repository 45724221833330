import React from 'react'
import { useEffect,useState } from 'react'
import axios from 'axios';
import { Api_url } from './helper'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Header from './Header';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
const Records = () => {
	const [records, setRecords] = useState([]);
	const handleRecords = async() => {
		const response = await axios.get(`${Api_url}/Otp/allusers`);
		console.log(response.data,'sshh')
		setRecords(response.data)
	}
	useEffect(() => {
		handleRecords();
	},[]
	)
  return (
	  <div>
		  <Header/>
		  <h1 style={{textAlign:'center'}}>Records</h1>
	  <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">DOB</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((row) => (
            <TableRow
              key={row.Name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Name}
              </TableCell>
              <TableCell align="right">{row.Email}</TableCell>
              <TableCell align="right">{row.dob}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
		  
	</div>
  )
}

export default Records