
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from './Header';
import { Grid, Typography, Button } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Api_url } from './helper';
import { Avatar } from '@mui/material';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FilesViewer = () => {
  const [extractedText, setExtractedText] = useState('');
  const [imageData, setImageData] = useState('');
  const [title, setTitle] = useState('');
	const [synopsis, setSynopsis] = useState('');
	const [photoUrl, setPhotoUrl] = useState(null);
  const [text, setText] = useState('');
	const paragraphsPerPage = 3;
	const [pages, setPages] = useState([]);
	const mobile = useMediaQuery('(max-width:600px)');
  const charactersPerParagraph = 1500;
	const [pageIndex, setPageIndex] = useState(0);
	const [pageNumber, setPageNumber] = useState(1);
	const [userData, setUserData] = useState(null);
	const [numPages, setNumPages] = useState(null);
	const [pdfBase64, setPdfBase64] = useState('');
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};
	const nextPage = () => {
		if (pageNumber < numPages) {
			const windowHeight = window.innerHeight; // Get the height of the viewport
			const scrollAmount = windowHeight * 0.2; // Calculate 30% of the viewport height
		
			// Scroll smoothly to the top of the page
			window.scrollTo({
			  top: mobile?820:250, // Scroll to the top
			  behavior: 'smooth' // Use smooth scrolling behavior
			});
		setPageNumber(pageNumber + 1);
	  }
	};
	
	const previousPage = () => {
		if (pageNumber > 1) {
			// const windowHeight = window.innerHeight; // Get the height of the viewport
			// const scrollAmount = windowHeight * 0.2; // Calculate 30% of the viewport height
		
			// // Scroll smoothly to the top of the page
			// window.scrollTo({
			//   top: mobile?820:250, // Scroll to the top
			//   behavior: 'smooth' // Use smooth scrolling behavior
			// });
		setPageNumber(pageNumber - 1);
	  }
	};
	const queryParams = new URLSearchParams(window.location.search);
	const fileId = queryParams.get('fileId');
  useEffect(() => {
    // Get the fileId from the query parameter
    const queryParams = new URLSearchParams(window.location.search);
	  const fileId = queryParams.get('fileId');
	  console.log(fileId,'file')

    // Make a GET request to the backend using the fileId
	axios
	.get(`${Api_url}/Otp/filesimg/${fileId}`)
		.then((response) => {
		console.log(response.data,'sd')
	  setPdfBase64(response.data.pdfBase64);
	})
	.catch((error) => {
	  console.error('Error fetching PDF:', error);
	});
  }, [fileId]);

  useEffect(() => {
    // Make a GET request to fetch the image and other details using the fileId
    const queryParams = new URLSearchParams(window.location.search);
    const fileId = queryParams.get('fileId');
    axios
      .get(`${Api_url}/Otp/api/images/${fileId}`)
      .then((response) => {
		  const { imageData, title, synopsis,userId } = response.data;
		  console.log(response.data);
		  setImageData(imageData);
		  
        setTitle(title);
		  setSynopsis(synopsis);
		  console.log(userId)
		  if (userId) {
			// If userId is present, make another GET request to fetch additional details
			axios.get(`${Api_url}/sign/user/${userId}`)
				.then((userDataResponse) => {
				  console.log(userDataResponse.data,'response')
				
				  setUserData(userDataResponse.data);
					
					console.log(userData,'statev')
			  })
			  .catch((error) => {
				console.error('Error fetching user data:', error);
			  });
			  axios.get(`${Api_url}/Otp/users/photo/${userId}`, {
				responseType: 'arraybuffer',
				headers: {
				  Accept: 'image/png, image/jpeg',
				},
			  })
			  .then((photoResponse) => {
				const blob = new Blob([photoResponse.data], { type: photoResponse.headers['content-type'] });
				const url = URL.createObjectURL(blob);
				setPhotoUrl(url);
			  }).catch((error) => {
				console.error('Error fetching user photo:', error);
			  });
		  }
      })
      .catch((error) => {
        console.error('Error fetching image and details:', error);
      });
  }, []);
 

  const handleNextPage = () => {
    setPageIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevPage = () => {
    setPageIndex((prevIndex) => prevIndex - 1);
  };

  const currentPageParagraphs = pages.slice(
    pageIndex * paragraphsPerPage,
    pageIndex * paragraphsPerPage + paragraphsPerPage
  );
	const handleAccept = async () => {
		const queryParams = new URLSearchParams(window.location.search);
		const fileId = queryParams.get('fileId');
	try {
	  await axios.put(`${Api_url}/Otp/api/images/${fileId}`, { status: 'accepted' });
	  alert('Status updated to "accepted"');
	} catch (error) {
	  console.error(error);
	  alert('Failed to update status.');
	}
  };

	const handleReject = async () => {
		const queryParams = new URLSearchParams(window.location.search);
		const fileId = queryParams.get('fileId');
	try {
	  await axios.put(`${Api_url}/Otp/api/images/${fileId}`, { status: 'rejected' });
	  alert('Status updated to "rejected"');
	} catch (error) {
	  console.error(error);
	  alert('Failed to update status.');
	}
  };
  return (
    <div>
      <Header />
     
		  <Grid container lg={12}>
			  <Grid container lg={7} sx={{margin:'auto',marginTop:"50px",marginBottom:"96px"}}>
			  <Grid item lg={4}>
			  <img src={`data:image/jpeg;base64,${imageData}`} alt="Image" style={{ width: '240px', height: '303px',borderRadius:'12px' }} />
				  </Grid>  
				  <Grid item lg={8}>
				  <h2>{title}</h2>
					  <p>{synopsis}</p>
					  {userData ? <Grid container lg={2} sx={{alignItems:'center'}}>
						  <Grid item lg={7}>
						  <Avatar src={photoUrl} alt="Image"  />
						  </Grid>
						  <Grid item lg={5}>
						  <Typography sx={{fontSize:'16px',fontFmaily:'Inter',fontWeight:'600',color:'#000'}}>{userData.Name}</Typography>
							  </Grid>
						
					  </Grid>  : 'Please wait'}
				  </Grid>
			  </Grid>
			 
		  </Grid>
		  <Grid container lg={12} sx={{background:'#FAF7F7'}}>
			  <Grid item lg={8} sx={{margin:'auto',marginTop:"56px",marginBottom:"64px"}}>
				  {pdfBase64 && <div id="pdf-container" style={{ width: mobile ? '100%' : "", height: mobile ? '70vh' : "" }}>
					  <Document
						  file={`data:application/pdf;base64,${pdfBase64}`}
						  onLoadSuccess={onDocumentLoadSuccess}
						  onContextMenu={(e) => e.preventDefault()}
						  noData="Loading PDF..."
					  >
						  <Page pageNumber={pageNumber} renderTextLayer={false} width={mobile ? window.innerWidth * 0.9 : window.innerWidth * 0.65} // Adjust the width as needed
							  height={mobile ? window.innerHeight * 0.8 : window.innerHeight * 0.65}
							  renderAnnotationLayer={false} />
					  </Document>
				  </div>}
			
				
			
			  {numPages > 0 ? (
          <div>
						  {/* Render the paragraphs if there is text */}
						  

						  <Grid container lg={12} sx={{marginTop:'20px'}}>
							  
						 
						    <Grid item lg={6} sx={{display:'flex'}}>
					  
							{pageNumber > 1 &&
						  
						 
						  <Button variant='outlined'onClick={previousPage} style={{backgroundColor:'#007f85',color:'#FFFFFF',textDecoration:'none',textTransform:'none',borderRadius:'8px',fontSize:mobile ? '12px' :'16px',padding:'12px 20px',width:mobile ? '136px' :"185px",height:mobile ? '40px' :'48px',marginRight:'20px'}}>Show Previous</Button>
			
								  }
								  { pageNumber !== numPages &&
								<Button variant='outlined' onClick={nextPage} style={{ backgroundColor: '#007f85', color: '#FFFFFF', textDecoration: 'none', textTransform: 'none', borderRadius: '8px', fontSize: mobile ? '12px' : '16px', padding:mobile?"8px 16px": '12px 20px', width: mobile ? '136px' : "95px", height: mobile ? '40px' : '48px' }}>Next <KeyboardArrowRightIcon color='#FFFFFF' style={{ width: mobile ? "16px" :"18px",height:mobile ? "16px" :"18px"}} /> </Button>
							  	  
								  }
								  
					  
						  </Grid>
						 
      </Grid>
				
						
				
          </div>
       
				  ) : (<>
					 
						
				  
          <Typography
            sx={{ fontSize: '16px', fontFamily: 'Inter', fontWeight: '400',textAlign:'center', color: '#1E1E1E', lineHeight: '24.96px', marginBottom: '10px' }}
          >
            Want to read more?
						  </Typography>
						  <Typography
						  sx={{ fontSize: '10px', fontFamily: 'Inter', fontWeight: '400', color: '#1E1E1E', lineHeight: '24.96px',textAlign:'center', }}
						>
						  Vote this manuscript to help it get Published
						  </Typography>
						  <Grid container lg={12} sx={{marginTop:"25px"}}>
						  <Grid item lg={6} sx={{ display: 'flex' }}>
						  <Button variant='outlined' onClick={previousPage} style={{backgroundColor:'#007f85',color:'#FFFFFF',textDecoration:'none',textTransform:'none',borderRadius:'8px',fontSize:mobile ? '12px' :'16px',padding:'12px 20px',width:mobile ? '136px' :"185px",height:mobile ? '40px' :'48px',marginRight:'20px'}}>Show Previous</Button>  
						  </Grid>
						
							  </Grid>
						</>   )}
			  </Grid>
		  </Grid>
		  <button onClick={handleReject}>Rejected</button>
		  <button onClick={handleAccept}>accepted</button>
     
      <hr /> {/* Add a horizontal line between pages */}
      
    </div>
  );
};

export default FilesViewer;


